import GlobalBlackFridayBanner from '@/shared/blackFriday/GlobalBlackFridayBanner';
import ToastMessage from '@/shared/global/ToastMessage';
import UpgradeModal from '@/shared/UpgradeModal';
import { Frame } from '@shopify/polaris';
import { PATHS } from 'config/constants';
import { useToast } from 'hooks/useToast';
import { useUpgradeModalShow } from 'hooks/useUpgradeModalShow';
import React, { Suspense, lazy, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router';
import { dismissToast } from 'states/actions/toast';

const Home = lazy(() => import('./pages/dashboard/Dashboard'));
const Pricing = lazy(() => import('./pages/pricing/Pricing'));
const Help = lazy(() => import('./pages/help/Help'));
const Campaigns = lazy(() => import('./pages/campaigns/Campaigns'));
const ModifyCampaign = lazy(() => import('./pages/campaignV3/Campaign'));
const ModifyCampaignOld = lazy(() => import('./pages/campaign/Campaign'));
const SelectCampaign = lazy(() => import('./pages/campaignV2/SelectCampaign'));
const Setting = lazy(() => import('./pages/setting/Setting'));
const Widgets = lazy(() => import('./pages/widgetsV2/index'));
const CustomizeCountdown = lazy(() => import('./pages/countdown-settings/CustomizeCountdown'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Login = lazy(() => import('./pages/Login'));

const Router = () => {
  const dispatch = useDispatch();
  const upgradeModalShow = useUpgradeModalShow();
  // const shopInfo = useShopInfo();
  const toast = useToast();
  const handleDismissToast = useCallback(() => {
    dismissToast(dispatch);
  }, [dispatch]);

  return (
    <Suspense fallback={<div></div>}>
      <Frame>
        {/* <TopNav /> */}

        <GlobalBlackFridayBanner />

        <Routes>
          <Route path={PATHS.HOME} element={<Home />} />
          <Route path={PATHS.PRICING} element={<Pricing />} />
          <Route path={`${PATHS.HELP}`} element={<Help />} />
          <Route path={`${PATHS.HELP}/:tab`} element={<Help />} />

          <Route path={`${PATHS.CREATE_CAMPAIGNold}`} element={<ModifyCampaignOld />} />
          <Route path={`${PATHS.EDIT_CAMPAIGNold}/:campaignId`} element={<ModifyCampaignOld />} />
          <Route path={PATHS.SETTING} element={<Setting />} />
          <Route path={`${PATHS.COUNTDOWN_SETTINGS}`} element={<CustomizeCountdown />} />
          {/* <Route  path={PATHS.FEATURE_REQUEST} element={FeatureRequestPage} /> */}

          <Route path={`${PATHS.WIDGETS}`}>
            <Route index element={<Widgets />} />
            <Route path={`${PATHS.WIDGETS}/:tab`} element={<Widgets />} />
          </Route>
          <Route path={PATHS.CAMPAIGNS} element={<Campaigns />} />
          <Route path={`${PATHS.CREATE_CAMPAIGN}`} element={<ModifyCampaign />} />
          <Route path={`${PATHS.EDIT_CAMPAIGN}/:campaignId`} element={<ModifyCampaign />} />
          <Route path={PATHS.SELECT_CAMPAIGN} element={<SelectCampaign />} />
          <Route path={PATHS.LOGIN} element={<Login />} />
          <Route element={<NotFound />} />
        </Routes>

        {toast?.show && <ToastMessage handleDismiss={handleDismissToast} {...toast} />}
        {upgradeModalShow && <UpgradeModal />}
      </Frame>
    </Suspense>
  );
};

export default Router;
