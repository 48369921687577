export const checkIsStoreNew = ({ date }) => {
  let createdAt = new Date(date);
  let versionChangeDate = new Date('2021-09-29'); // replace with your second date

  if (createdAt > versionChangeDate) {
    return true;
  } else if (createdAt < versionChangeDate) {
    return false;
  }
};
