import { Banner, Modal } from '@shopify/polaris';
import React from 'react';
// import { CircleCancelMinor } from '@shopify/polaris-icons';

// import { useCurrentPlan } from 'hooks/useCurrentPlan';
// import { useIsFreePlan } from 'hooks/useIsFreePlan';
import { PATHS } from 'config/constants';
import { t } from 'i18next';
import { Link } from 'react-router';

const DowngradeModal = ({ isOpen, handleClose, planId, downgradePlan }) => {
  // const isFreePlanDowngrade = useIsFreePlan(planId);
  // const currentPlan = useCurrentPlan();

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      title={
        t('msg_downgrade_p1')
        // <div className="flexIconAndTextInTitle">
        //   <Icon source={RiskMinor} color="warning" />
        // </div>
      }
      primaryAction={{
        content: t('btn_downgrade'),
        onAction: () => {
          downgradePlan(planId);
          handleClose();
        },
        destructive: true,
      }}
      secondaryActions={[
        {
          content: t('btn_cancel'),
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <Banner tone="warning">
          {t('msg_downgrade_banner')}{' '}
          <Link to={`${PATHS.HELP}/${PATHS.HELP_SUB_PAGES.CONTACT_US}`}>
            {t('contact_support')}
          </Link>
        </Banner>
        <div style={{ marginTop: '15px' }}>
          <p></p>
          <p>{t('msg_downgrade_p2')}</p>
        </div>

        {/* <Stack vertical> */}
        {/* {isFreePlanDowngrade && (
            <Banner status="info">
              <p>
                Your plan will downgrade when you finish{' '}
                <TextStyle variation="strong">one month</TextStyle> of your current plan.
              </p>
            </Banner>
          )} */}
        {/* <Heading>You will lose these key features:</Heading> */}
        {/* <Stack vertical>
            {currentPlan?.top_features?.map((feature, index) => (
              <Stack key={index} alignment="center" spacing="tight">
                <Icon source={CircleCancelMinor} color="critical" />
                <p>{feature}</p>
              </Stack>
            ))}
          </Stack> */}
        {/* </Stack> */}
      </Modal.Section>
    </Modal>
  );
};

export default DowngradeModal;
