"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchInstance = exports.shopDomain = void 0;
exports.setApiAppBridge = setApiAppBridge;
const app_bridge_1 = require("@shopify/app-bridge");
const app_bridge_utils_1 = require("@shopify/app-bridge-utils");
const apiUrl = process.env.REACT_APP_API_BASE_URL;
exports.shopDomain = undefined;
let shopifyApp = undefined;
function setApiAppBridge(data) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!!shopifyApp)
            return;
        exports.shopDomain = data.shopDomain;
        shopifyApp = (0, app_bridge_1.createApp)({
            apiKey: process.env.REACT_APP_API_KEY,
            host: data.host || '',
            forceRedirect: false,
        });
    });
}
const fetchInstance = (_a) => __awaiter(void 0, [_a], void 0, function* ({ url, method, params, data, headers, responseType, }) {
    const baseUrl = `${apiUrl}${url}`;
    const finalUrl = params ? `${baseUrl}?${new URLSearchParams(params)}` : baseUrl;
    let token;
    if (!!shopifyApp)
        token = yield (0, app_bridge_utils_1.getSessionToken)(shopifyApp);
    const response = yield fetch(finalUrl, Object.assign(Object.assign({ method }, (data ? { body: JSON.stringify(data) } : {})), { headers: Object.assign(Object.assign({}, (headers || {})), { Authorization: !!token ? `Bearer ${token}` : '', ShopDomain: exports.shopDomain || '', 'ngrok-skip-browser-warning': 'true' }) }));
    return response.json();
});
exports.fetchInstance = fetchInstance;
exports.default = exports.fetchInstance;
