import { Icon, InlineStack, Text } from '@shopify/polaris';
import { ClockIcon } from '@shopify/polaris-icons';
import React, { useEffect, useRef, useState } from 'react';
import { blackFridayDate } from 'utils/blackFridayBanner';

const BlackFridayTimer = () => {
  const CountdownRef = useRef(null);
  const [timer, setTimer] = useState({ d: '00', h: '00', m: '00', s: '00' });

  const getTimeRemaining = () => {
    const total = Date.parse(blackFridayDate) - Date.parse(new Date());

    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    const days = Math.floor(total / 1000 / 60 / 60 / 24);
    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = () => {
    let { total, days, hours, minutes, seconds } = getTimeRemaining();
    if (total >= 0) {
      setTimer({
        d: days,
        h: hours > 9 ? hours : '0' + hours,
        m: minutes > 9 ? minutes : '0' + minutes,
        s: seconds > 9 ? seconds : '0' + seconds,
      });
    } else {
      setTimer({ d: '00', h: '00', m: '00', s: '00' });
    }
  };

  const clearTimer = (e) => {
    if (CountdownRef.current) clearInterval(CountdownRef.current);
    const id = setInterval(() => {
      startTimer();
    }, 1000);
    CountdownRef.current = id;
  };

  useEffect(() => {
    clearTimer();

    return () => {
      if (CountdownRef.current) {
        clearInterval(CountdownRef.current);
      }
    };
  }, []);

  return (
    <InlineStack gap="100" align="center" blockalign="center">
      <Icon source={ClockIcon} tone="inherit" />
      {timer.d > 0 ? (
        <Text as="div">
          <span className="white-color">{timer.d}d</span>
        </Text>
      ) : null}
      <div style={{ width: 56 }}>
        <Text as="div">
          <span className="white-color">{`${timer.h}:${timer.m}:${timer.s}`}</span>
        </Text>
      </div>
    </InlineStack>
  );
};

export default BlackFridayTimer;
